


























































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { GlobalRuleValidations } from "@/constants/validations/global-rule.validation";
import { IGlobalRule } from "@/interfaces/runPricingModel/globalRule.interface";
import { validationMixin } from "vuelidate";
import { withPopper } from "@/plugins/popover";
import GlobalRuleService from "@/services/global-rule.service";
import { ParametersService } from "@/services/parameters.service";
import RunPricingModelModule from "@/store/modules/runPricingModel.module";
import { getModule } from "vuex-module-decorators";
import DynamicParams from "./parameters/DynamicParams.vue";
import { operationTypes } from "@/components/run-pricing-model/parameters/constants/operationTypes.const";
import {
  IRunPricingCategory,
  IRunPricingColumn,
  RunPricingColumnType,
  RunPricingDataType,
} from "@/interfaces/runPricingModel/parameters/runPricingModelConfig.interface";
import { IParamData } from "@/interfaces/runPricingModel/parameters/commonParam.interface";
import { checkIfValid } from "@/utils/validators";
import { isRunPricingParameterValid } from "@/constants/validations/run-pricing-model-parameters.validation";
import { Dictionary } from "lodash";
import moment from "moment";
import { IVectorParamValue } from "@/interfaces/runPricingModel/parameters/vectorParamValue.interface";
import VectorParam from "./parameters/VectorParam.vue";
import { validPromise } from "@/utils/validPromise";
import { RPMPagesType } from "@/enums/rpm-pages-type";

@Component({
  mixins: [validationMixin],
  validations: GlobalRuleValidations,
  components: {
    "dynamic-params": DynamicParams,
    "vector-param": VectorParam,
  },
  methods: {
    updateName: function (this: any, e: any) {
      this.form.name = e;
      this.$v.form.$touch();
    },
  },
  data() {
    return {
      isRouteLeaving: false,
      isRedirectToPrevious: false,
      nextRouteName: String,
      operationTypes,
      nextRoute: Function,
      parametersService: ParametersService,
    };
  },
  async beforeRouteLeave(to, from, next) {
    this.$data.nextRouteName = to.name;
    this.$data.form.parameters = this.$data.parametersService.FormatDateParameters(this.$data.form.parameters, "LL", false);
    await this.formChanged();
    this.$data.isRouteLeaving = this.isDirty;

    if (this.$data.isRedirectToPrevious) {
      this.$data.isRouteLeaving = false;
    }

    if (!this.$data.isRouteLeaving) {
      next();
    }
    this.$data.isRedirectToPrevious = false;
    this.$data.nextRoute = next;
  },
})
export default class GlobalRule extends Vue {
  // refs

  public popover = withPopper;
  public form: IGlobalRule = {
    name: "",
    id: 0,
    description: "",
    parameters: [],
    settings: {
      isOperationsEnable: false,
    },
    sequence: 0,
    isYtm: false,
  };
  public pristineForm: IGlobalRule = {
    name: "",
    id: 0,
    description: "",
    parameters: [],
    settings: {
      isOperationsEnable: false,
    },
    sequence: 0,
    isYtm: false,
  };
  runPricingModelModule: RunPricingModelModule;
  public isSaveStateAfterSubmit: boolean = false;
  public isInvalid: boolean = false;
  public ytmSpreadVector: IRunPricingColumn | null = null;
  public isGLobalRuleUpdated: boolean = false;
  public ytmVectorValue: IParamData = {
    value: {
      segments: null,
      rangeValue: null,
    },
    inputValue: null,
    type: RunPricingDataType.LIST_FLOAT,
    columnName: "",
    pairName: "",
    columnType: RunPricingColumnType.Param,
    isOperation: false,
    operationType: null,
    operationValue: null,
    isValid: true,
    isOperationToggleOn: false,
  };
  public isDirty: boolean = false;

  private categories: IRunPricingCategory[] | null;
  private filteredCategories: IRunPricingCategory[] | null;

  public constructor() {
    super();

    this.runPricingModelModule = getModule(RunPricingModelModule);
    this.categories =
      this.runPricingModelModule.runPricingParametersInfoState?.categories ??
      null;
  }

  public created(): void {
    if (!this.categories) {
      this.runPricingModelModule.getRunPricingParametersInfo().then((value) => {
        this.categories = value.categories;
        this.getYtmSpreadVectorColumnInfo();
      });
    } else {
      this.getYtmSpreadVectorColumnInfo();
    }

    this.loadGlobalRuleModelState();
  }

  protected getEmptyForm(): IGlobalRule {
    return {
      name: "",
      id: 0,
      description: "",
      parameters: [],
      settings: {
        isOperationsEnable: false,
      },
      sequence: 0,
      isYtm: false,
    };
  }

  private loadGlobalRuleModelState(): void {
    const lastGlobalRule: IGlobalRule | null =
      this.getLastGlobalRuleFromStore();
    if (
      (lastGlobalRule?.id || this.$route.params.id) &&
      !(lastGlobalRule && +this.$route.params.id === lastGlobalRule?.id)
    ) {
      this.loadRule(
        +this.$route.params.id
          ? +this.$route.params.id
          : <number>lastGlobalRule?.id
      );
    } else {
      if (
        lastGlobalRule &&
        (+this.$route.params.id === lastGlobalRule?.id || !lastGlobalRule?.id)
      ) {
        lastGlobalRule.parameters = ParametersService.FormatDateParameters(
          lastGlobalRule.parameters
        );
        this.pristineForm = JSON.parse(JSON.stringify(lastGlobalRule));
        this.form = lastGlobalRule;
        const vectorValue = this.form.parameters.find((par) => {
          return par.columnName == "base_spot_rate";
        });
        if (vectorValue) {
          this.ytmVectorValue = vectorValue;
        }
      } else {
        this.pristineForm = this.getEmptyForm();
        this.form = this.getEmptyForm();
      }
    }
  }

  private getLastGlobalRuleFromStore(): IGlobalRule | null {
    return <IGlobalRule>(
      this.runPricingModelModule?.runPricingModelState?.globalRule
    );
  }

  public isControlValid(fieldName: string): boolean {
    return checkIfValid(this.$v.form, fieldName);
  }

  public validate(): void {
    this.$v.$touch();
    this.$v.form.$touch();
  }

  public async submit(): Promise<void> {
    this.validate();
    if(this.$v.form.$pending) {
      await validPromise.call(this, 'form');
    }
    this.isInvalid = this.isParamsInvalid();
    if (this.isInvalid || this.$v.form.$invalid) {
      this.isInvalid = true;
      this.$data.isRouteLeaving = false;
      return;
    } else {
      this.form.parameters.map((param) => {
        param.value = param.isOperation
          ? operationTypes.find((val) => val.value === param.operationType)
              .name + param.operationValue
          : param.inputValue;
        param.operationType = param.isOperation ? param.operationType : null;
        param.operationValue = param.isOperation ? param.operationValue : null;
      });
      this.setYtmVectorValueParameter();

      this.form.id ? this.updateRule() : this.createRule();
    }
  }

  private setYtmVectorValueParameter(): void {
    let existingParameterIndex = this.form.parameters.findIndex((par) => {
      return par.columnName == "base_spot_rate";
    });
    if (this.ytmVectorValue && !this.form.isYtm) {
      if (existingParameterIndex != -1) {
        this.form.parameters[existingParameterIndex] = this.ytmVectorValue;
      } else {
        this.form.parameters.push(this.ytmVectorValue);
      }
    } else {
      if (existingParameterIndex != -1) {
        this.form.parameters.splice(existingParameterIndex, 1);
      }
    }
  }

  public async loadRule(id: number): Promise<void> {
    GlobalRuleService.getById(id).then((value) => {
      value.parameters = ParametersService.FormatDateParameters(
        value.parameters
      );
      this.pristineForm = JSON.parse(JSON.stringify(value));
      this.form = value;
      this.form.parameters.map((param) => {
        param.inputValue = !param.isOperation ? param.value : null;
      });
      this.pristineForm = JSON.parse(JSON.stringify(this.form));
      if (!this.form.settings) {
        this.form.settings = {
          isOperationsEnable: false,
        };
        this.pristineForm.settings = {
          isOperationsEnable: false,
        };
      }
      const vectorValue = this.form.parameters.find((par) => {
        return par.columnName == "base_spot_rate";
      });
      if (vectorValue) {
        this.ytmVectorValue = vectorValue;
      }
    });

    await validPromise.call(this, "form");
  }

  public saveState(): void {
    let state = this.runPricingModelModule.runPricingModelState;
    if (state) {
      state.globalRule = this.$v.form.$dirty ? this.form : null;
      if (!this.isGLobalRuleUpdated) {
        state.selectedGlobalRule = this.form;
        this.isGLobalRuleUpdated = false;
      }
      if (state.selectedScenario) {
        state.selectedScenario.globalRules =
          state.selectedScenario.globalRules.map((x) =>
            x.id === this.form.id ? (x = this.form) : x
          );
      }

      this.runPricingModelModule.updateState(state);
    }
  }

  public saveParamsData(data: IParamData[]): void {
    this.form.parameters = data;
  }

  public returnToRunPricingModel({
    item = "RunPricingModel",
    save = true,
    redirectToPrevious = false,
  } = {}): void {
    let routerParams: Dictionary<string> | undefined;
    if (
      this.runPricingModelModule.runPricingModelState &&
      this.runPricingModelModule.runPricingModelState.selectedScenario
    ) {
      if (this.runPricingModelModule.runPricingModelState.selectedScenario.id) {
        routerParams = {
          id: this.runPricingModelModule.runPricingModelState.selectedScenario.id.toString(),
        };
      }
    }

    if (save && this.isSaveStateAfterSubmit) {
      this.saveState();
      this.isSaveStateAfterSubmit = false;
    } else {
      let state = this.runPricingModelModule.runPricingModelState;
      if (state) {
        if (!this.isGLobalRuleUpdated) {
          if (
            state.selectedScenario &&
            !state.selectedScenario.globalRules.some(
              (x) => x.id === this.pristineForm.id
            )
          ) {
            if (
              JSON.stringify(this.pristineForm) !==
              JSON.stringify(this.getEmptyForm())
            ) {
              state.selectedGlobalRule = this.pristineForm;
            }
          }
          this.isGLobalRuleUpdated = false;
        }
        if (state.selectedScenario) {
          state.selectedScenario.globalRules =
            state.selectedScenario.globalRules.map((x) =>
              x.id === this.form.id ? (x = this.pristineForm) : x
            );
        }
        state.globalRule = this.getEmptyForm();
        this.runPricingModelModule.updateState(state);
      }
    }

    if (this.$data.nextRoute && !redirectToPrevious) {
      this.$data.nextRoute();
      return;
    }

    this.$router.push({
      name:
        typeof this.$data.nextRouteName === "string"
          ? this.$data.nextRouteName
          : item,
      params: routerParams,
    });
  }

  @Watch("form", { deep: true })
  public async formChanged() {
    this.isDirty =
      await this.runPricingModelModule.checkFormIsDirty({ 
        form: this.form,
        pristineForm: this.pristineForm,
        type: RPMPagesType.GlobalRule
      });
  }

  public isParamsInvalid(): boolean {
    const columns: IRunPricingColumn[] | undefined = this.categories?.flatMap(
      (c) => c.columns
    );
    return columns
      ? this.form.parameters.some(
          (value) => !isRunPricingParameterValid(value, columns)
        )
      : false;
  }

  public onHideOrShowOperations(value: boolean): void {
    if (!value) {
      const columns: IRunPricingColumn[] | undefined = this.categories?.flatMap(
        (c) => c.columns
      );
      this.form.parameters.map((param) => {
        param.isOperation = false;
        if (!param.isOperation && !param.inputValue)
          param.inputValue = columns?.find(
            (c) => c.columnName == param.columnName
          )?.defaultValue;
      });
    } else {
      this.form.parameters.map((param) => {
        param.isOperation = param.isOperationToggleOn;
      });
    }
  }

  private createRule(): void {
    GlobalRuleService.create(this.form)
      .then((ruleId: number) => {
        this.form.id = ruleId;
        this.pristineForm = this.form;
        Vue.$toast.clear();
        Vue.$toast.success(`Global Rule ${this.form.name} created`);
        this.$data.isRedirectToPrevious = true;
        this.returnToRunPricingModel({ redirectToPrevious: true });
      })
      .catch((error) => {
        Vue.$toast.clear();
        switch (error.request.status) {
          case 409:
            Vue.$toast.error("Global Rule already exists");
            break;
          default:
            Vue.$toast.error(error);
        }
      });
  }

  private updateRule(): void {
    if (!this.form.id) {
      return;
    }
    GlobalRuleService.update(this.form.id, this.form)
      .then(() => {
        Vue.$toast.clear();
        Vue.$toast.success(`Global Rule ${this.form.name} updated`);
        this.pristineForm = this.form;
        this.isGLobalRuleUpdated = true;
        this.$data.isRedirectToPrevious = true;
        this.returnToRunPricingModel({ redirectToPrevious: true });
      })
      .catch((error) => {
        Vue.$toast.clear();
        Vue.$toast.error(error);
      });
  }

  private getYtmSpreadVectorColumnInfo(): void {
    this.categories?.forEach((category) => {
      const ytmSpreadVectorIndex = category.columns.findIndex((col) => {
        return col.columnName === "base_spot_rate";
      });
      if (ytmSpreadVectorIndex != -1) {
        this.ytmSpreadVector = category.columns[ytmSpreadVectorIndex];
      }
    });
    this.setCategoriesWithoutYtmSpreadVector();
  }

  public setCategoriesWithoutYtmSpreadVector(): void {
    if (!this.categories) return;
    this.filteredCategories = this.categories.map((category) => {
      return {
        ...category,
        columns: category.columns.filter(
          (column) => column.columnName !== "base_spot_rate"
        ),
      };
    });
  }

  public isListOfInts(value: any): boolean {
    return value && value.match(/^(\d+(?:[ \t]*,[ \t]*\d+)*)?$/) != null;
  }

  public writeParamValue(data: IParamData): void {
    this.ytmVectorValue = data;
    this.setYtmVectorValueParameter();
  }

  public removeParamsData(data: IParamData): void {
    this.form.parameters = this.form.parameters.filter((pd) => {
      return pd.columnName !== data.columnName;
    });
    this.ytmVectorValue = {
      value: {
        segments: null,
        rangeValue: null,
      },
      inputValue: null,
      type: RunPricingDataType.LIST_FLOAT,
      columnName: "",
      pairName: "",
      columnType: RunPricingColumnType.Param,
      isOperation: false,
      operationType: null,
      operationValue: null,
      isValid: true,
      isOperationToggleOn: false,
    };
  }
}
